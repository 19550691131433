import {
  ContextAwareTitle,
  DeletedOption,
  DeletedOptionGroup,
  OptionGroup,
  TranslationSet,
} from "../../db/models/Question";

export enum ReclassificationStatus {
  Added = "ADDED",
  AsReported = "AS_REPORTED",
  Removed = "REMOVED",
}
export interface Question {
  id: string;
  title: string;
  titleTranslations: TranslationSet;
  questionType: QuestionType;
  optionGroups: OptionGroup[] | null;
  deletedOptionGroups: DeletedOptionGroup[] | null;
  deletedOptions: DeletedOption[] | null;
  contextAwareTitles: ContextAwareTitle[];
}

export interface Answer {
  questionId: string;
  id: number;
  optionId: string | null;
  optionText: string | null;
  freeText: string | null;
  imageUrl: string | null;
  redacted: boolean;
  reclassifiedStatus: ReclassificationStatus;
}

export enum QuestionType {
  Text = "TEXT",
  Checklist = "CHECKLIST",
  Radio = "RADIO",
  Image = "IMAGE",
  Date = "DATE",
  Time = "TIME",
  Datetime = "DATETIME",
  Textarea = "TEXTAREA",
  Phone = "PHONE",
  Email = "EMAIL",
}
export interface QuestionWithAnswers {
  question: Question;
  answers?: Answer[];
}
export interface Report {
  report: {
    form: {
      name: string;
    };
    questions: QuestionWithAnswers[];
  };
}

export interface Option {
  id: string;
  value: string;
  valueTranslations?: TranslationSet;
}
