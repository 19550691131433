import React, { Fragment, PropsWithChildren } from "react";

import { DeletedOption } from "../../../../__generated__/graphql";
import { OptionGroup } from "../../../../db/models/Question";
import Badge from "../../../../shared/components/design-system/Badge";
import Icon from "../../../../shared/components/design-system/Icon";
import { Translated } from "../../../report-utils/Translated";
import { Answer, Question, QuestionType, QuestionWithAnswers, Report } from "../../../report-utils/types";
import { useTranslate } from "../../../translation/frontend";
import FreeTextAnswer from "./FreeTextAnswer";
import ImageAnswer from "./ImageAnswer";
import OptionsAnswer from "./OptionsAnswer";
import styles from "./styles.module.scss";

export type AnswerList = Array<{ title: OptionGroup | DeletedOption | null; answers: Answer[] }>;
type ReportAnswerQuestion = QuestionWithAnswers["question"];

export default function AnsweredQuestion({
  answeredQuestion,
  report,
}: {
  answeredQuestion: QuestionWithAnswers;
  report: Report;
}) {
  const answers = answeredQuestion.answers;

  const nested: AnswerList = [];
  if (answers) {
    for (const answer of answers) {
      const title = answer.optionId ? getOptionGroupName(answer.optionId, answeredQuestion.question) : null;
      const target = nested;
      const existingGroup = target.find((group) => title && group.title === title);
      if (existingGroup) {
        existingGroup.answers.push(answer);
      } else {
        target.push({ title, answers: [answer] });
      }
    }
  }
  const questionType = answeredQuestion.question.questionType;

  return (
    <AnsweredQuestionWrapper answeredQuestion={answeredQuestion} report={report}>
      <div className={styles.answersWrapper}>
        <NestedAnswers answers={nested} questionType={questionType} />
      </div>
    </AnsweredQuestionWrapper>
  );
}

function getOptionGroupName(optionId: string, question: Question): OptionGroup | DeletedOption | null {
  for (const group of question.optionGroups ?? []) {
    if (group.options.some((option) => option.id === optionId)) {
      return group ? group : null;
    }
  }

  for (const option of question.deletedOptions ?? []) {
    if (option.id === optionId) {
      for (const group of question.deletedOptions ?? []) {
        if (group.id === option.id) {
          return group;
        }
      }
      for (const group of question.deletedOptionGroups ?? []) {
        if (group.id === option.groupId) {
          return group
            ? {
                id: group.id,
                groupName: group.groupName,
                groupNameTranslations: group.groupNameTranslations,
                options: [],
              }
            : null;
        }
      }
    }
  }
  return null;
}

function NestedAnswers({ answers, questionType }: { answers: AnswerList; questionType?: QuestionType }) {
  const redacted = answers.flatMap(({ answers }) => answers).some((answers) => answers.redacted);
  const translate = useTranslate();
  if (questionType === QuestionType.Image) {
    return (
      <div className={styles.answerWrapper}>
        <div className={styles.answerWrapper}>
          <Icon icon="arrowUp" className={styles.reportAnswerIcon} size={20} />
          {answers.length > 0 ? (
            <div>
              {redacted ? <Badge label={translate("redacted")} variant="warning" className="ds-mb-3" /> : null}
              <div className={styles.imageQuestion}>
                {answers.map(({ answers }, i) => (
                  <Fragment key={i}>
                    {answers
                      .filter((answer) => !answer.redacted)
                      .map((answer) => (
                        <div key={answer.id}>{answer.imageUrl ? <ImageAnswer imageUrl={answer.imageUrl} /> : null}</div>
                      ))}
                  </Fragment>
                ))}
              </div>
            </div>
          ) : (
            <div className="report-answer">
              <Badge label={translate("notAnswered")} variant="warning" />
            </div>
          )}
        </div>
      </div>
    );
  }

  if (questionType === QuestionType.Checklist || questionType === QuestionType.Radio) {
    return answers.length > 0 ? (
      <OptionsAnswer questionType={questionType} answers={answers} />
    ) : (
      <div className={styles.redactableReportAnswer}>
        <Icon icon={null} size={20} className={styles.reportAnswerIcon} />
        <div className={styles.reportAnswer}>
          <Badge label={translate("notAnswered")} variant="warning" />
        </div>
      </div>
    );
  }

  return <FreeTextAnswer answers={answers} redacted={redacted} />;
}

function anyAnswerMatches(answers: Answer[] | undefined, optionId: string): boolean {
  return answers ? answers.some((answer) => answer.optionId === optionId) : false;
}

function onlyIfMatches(onlyIf: ReportAnswerQuestion["contextAwareTitles"][number]["when"], answers: Answer[]) {
  const title = Object.entries(onlyIf).every(([_, matches]) =>
    Array.isArray(matches)
      ? matches.some((match) => anyAnswerMatches(answers, match))
      : anyAnswerMatches(answers, matches),
  );
  return title;
}

export function contextAwareTitle(question: Question, answers: Answer[]) {
  for (const contextAwareTitle of question.contextAwareTitles) {
    if (onlyIfMatches(contextAwareTitle.when, answers)) {
      return <Translated translations={contextAwareTitle.titleTranslations}>{contextAwareTitle.title}</Translated>;
    }
  }
  return <Translated translations={question.titleTranslations}>{question.title}</Translated>;
}

function AnsweredQuestionWrapper({
  answeredQuestion,
  report,
  children,
}: PropsWithChildren<{
  answeredQuestion: QuestionWithAnswers;
  report: Report;
}>) {
  return (
    <div key={answeredQuestion.question.id} className={styles.questionWrapper}>
      <p className={styles.questionTitle}>
        {contextAwareTitle(
          answeredQuestion.question,
          report.report.questions.flatMap(({ answers }) => answers!),
        )}
      </p>
      {children}
    </div>
  );
}
