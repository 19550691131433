import React, { Fragment, FunctionComponent, useCallback } from "react";

import { AnswerTemplate } from "../../../db/models/Answer";
import { FormInterstitial } from "../../../db/models/Form";
import Question from "../../../db/models/Question";
import { Translated } from "../../report-utils/Translated";
import { useTranslate } from "../../translation/frontend";
import { PaginationWide } from "../Pagination";
import { QuestionTitle } from "../Question";
import { useForm } from "../context";
import CheckIcon from "../icons/CheckIcon";
import { shouldSkipPage } from "../page-logic";
import { InterstitialBody } from "./components";

export interface ReviewInterstitialProps {
  page: FormInterstitial;
}

const GroupedAnswers: FunctionComponent<{ question: Question; answers: AnswerTemplate[] }> = ({
  question,
  answers,
}) => {
  const answersToOptionGroup: Record<string, string> = {};
  for (const answer of answers) {
    const optionGroupId = question.optionGroups?.find(({ options }) =>
      options.some(({ id }) => id === answer.optionId),
    )?.id;
    if (!optionGroupId) continue;
    answersToOptionGroup[answer.optionId!] = optionGroupId;
  }

  const usedOptionGroups = new Set<string>(Object.values(answersToOptionGroup));

  return (
    <>
      {question
        .optionGroups!.filter(({ id }) => usedOptionGroups.has(id))
        .map(({ groupName, id }) => (
          <Fragment key={id}>
            <p className="report-review-answer-group">{groupName}</p>
            <ul className="report-review-answers">
              {answers
                .filter((answer) => answersToOptionGroup[answer.optionId!] === id)
                .map((answer, i) => (
                  <li className="report-review-answer" key={answer.optionId!}>
                    <ReviewAnswer question={question} answer={answer} />
                  </li>
                ))}
            </ul>
          </Fragment>
        ))}
    </>
  );
};

export const ReviewAnswer: FunctionComponent<{ question: Question; answer: AnswerTemplate }> = ({
  question,
  answer,
}) => {
  const translate = useTranslate();

  if (answer.optionId) {
    // We can't use question.getOptionById here because we might in the browser where the questions aren't fully realised Sequelize models but plain JSON representations of them
    for (const group of question.optionGroups!) {
      for (const option of group.options) {
        if (option.id === answer.optionId) {
          return (
            <>
              <Translated translations={option.valueTranslations}>{option.value}</Translated>
              {answer.freeText ? `: ${answer.freeText}` : ""}
            </>
          );
        }
      }
    }
    // In theory there should always be a matching option, because how could you have picked an option that doesn't exist? In practice it's technically possible, if the user is not using JavaScript and the options change while they are completing the form. In that event someone could see untranslated text, but it's so desperately unlikely to ever come up that mitigating it any further than this seems like a waste of time
    return (
      <>
        {answer.optionText}
        {answer.freeText ? `$: ${answer.freeText}` : ""}
      </>
    );
  }

  if (answer.freeText) return <>{answer.freeText}</>;

  return <em>{translate("questionNotAnswered")}</em>;
};

const ChangeButton: FunctionComponent<{
  questionId: string;
  pageNumber: number;
  onChange: (pageNumber: number, focusQuestion?: string) => void;
}> = ({ pageNumber, questionId, onChange }) => {
  const translate = useTranslate();
  const handleClick = useCallback(() => {
    onChange(pageNumber, questionId);
  }, [onChange, pageNumber, questionId]);

  return (
    <button
      className="report-review-change"
      type="submit"
      name="_change"
      value={pageNumber.toString()}
      onClick={handleClick}
    >
      {translate("change")}
    </button>
  );
};

const ReviewInterstitial: FunctionComponent<ReviewInterstitialProps> = ({ page }) => {
  const translate = useTranslate();
  const { pages, answers, onChangePage, isSurvey } = useForm();

  return (
    <>
      <section className="form-group-intro section">
        <div className="container--slim">
          <div className="report-review-intro">
            <InterstitialBody body={page.body} />
          </div>
        </div>
      </section>

      <section className="form-group section">
        <div className="container--slim">
          <h2>{translate(isSurvey ? "reviewSurveyAnswers" : "reviewAnswers")}</h2>
          {pages.map((page, i) =>
            page.type !== "QUESTION_GROUP" || shouldSkipPage(page, answers) ? null : (
              <Fragment key={i}>
                {page.questions.map((question) => {
                  const questionAnswers = answers[question.id] ?? [];
                  return (
                    <Fragment key={question.id}>
                      <h3 className="report-review-question">
                        <QuestionTitle question={question} answers={answers} />
                      </h3>
                      {question.questionType === "IMAGE" ? (
                        <p className="report-review-answer">
                          {translate("imagesAttached", { count: questionAnswers.length })}{" "}
                          <ChangeButton questionId={question.id} pageNumber={i + 1} onChange={onChangePage} />
                        </p>
                      ) : questionAnswers.length === 0 ? (
                        <p className="report-review-answer">
                          <em>{translate("questionNotAnswered")}</em>{" "}
                          <ChangeButton questionId={question.id} pageNumber={i + 1} onChange={onChangePage} />
                        </p>
                      ) : questionAnswers.length === 1 &&
                        (!question.optionGroups || question.optionGroups.length === 1) ? (
                        <p className="report-review-answer">
                          <ReviewAnswer question={question} answer={questionAnswers[0]} />{" "}
                          <ChangeButton questionId={question.id} pageNumber={i + 1} onChange={onChangePage} />
                        </p>
                      ) : (
                        <>
                          <GroupedAnswers question={question} answers={questionAnswers} />
                          <p>
                            <ChangeButton questionId={question.id} pageNumber={i + 1} onChange={onChangePage} />
                          </p>
                        </>
                      )}
                    </Fragment>
                  );
                })}
              </Fragment>
            ),
          )}
        </div>
      </section>

      {page.confirm ? (
        <section className="form-group section">
          <div className="container--slim">
            <div className="field field--isRequired field--isSingle">
              <div className="options__option options__option--interstitial">
                <div className="options__label">
                  <input
                    type="checkbox"
                    className="form-control options__check options__checkbox"
                    id="confirm"
                    required
                  />
                  <CheckIcon />
                  <div className="options__label-target">
                    <label htmlFor="confirm">{page.confirm}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      <PaginationWide />
    </>
  );
};

export default ReviewInterstitial;
