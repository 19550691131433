import type { Breadcrumb } from "@sentry/browser";
import * as Sentry from "@sentry/browser";
import React from "react";
import { hydrate, render } from "react-dom";

import { LoginSettingsInput } from "../../../__generated__/graphql";
import { RichTextDocument } from "../../../shared/block-editor-data/types";
import { ToastProvider } from "../../../shared/components/design-system/Toaster/context";
import ReportForm from "../../form";
import sendBeacon from "../../form/beacon";
import type { SiteEnvironment } from "../../site";
import { LanguageProvider } from "../../translation/frontend";
import ReporterMessaging from "../../your-case/App";
import "../css/globals.css";
import "../scss/index.scss";
import "./auth";
import CookieBanner from "./cookieBanner";
import CookieControl, { cookieAllowed, cookieBlocked, loadGoogleAnalytics } from "./cookieControl";
import initTitleObscurer from "./titleObscurer";
import wireUpFormLinks from "./wire-up-form-links";

declare global {
  interface Window {
    env: SiteEnvironment;
    cognitoSettings?: LoginSettingsInput;
    sla?: RichTextDocument;
  }
}

const uuids = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;

function onContentLoad() {
  const language = document.querySelector("html")!.getAttribute("lang")!;

  // Navigation events are used by the form to control what page it's on,
  // so instead of firing this link, focus the page header with JS.
  // This does not need to run in no-JS mode since the no-JS is not affected by navigation events.
  document.getElementById("skip-to-content")?.addEventListener("click", (e) => {
    e.preventDefault();
    document.getElementById("page-heading")?.focus();
  });

  const cookieControlCheckbox = document.getElementById("cookie-control") as HTMLInputElement | null;
  if (cookieControlCheckbox) {
    new CookieControl(cookieControlCheckbox).init();
  }

  const universalAnalyticsBlock = document.getElementById("universal-analytics-block");
  const googleTagBlock = document.getElementById("google-tag-block");
  if (universalAnalyticsBlock || googleTagBlock) {
    if (cookieAllowed()) {
      loadGoogleAnalytics(!!universalAnalyticsBlock, googleTagBlock?.dataset.googleTagId);
    } else if (!cookieBlocked()) {
      new CookieBanner(document.getElementById("cookie-banner") as HTMLDivElement).init();
    }
  }

  document.getElementById("copy-code-button")?.addEventListener("click", (e) => {
    const copyText = document.getElementById("message-code") as HTMLInputElement;
    const popover = document.getElementsByClassName("copy-popover")[0] as HTMLElement;
    popover.style.display = "block";
    void navigator.clipboard.writeText(copyText?.value);

    setTimeout(() => {
      popover.style.display = "none";
    }, 1000);
  });

  // For browsers that don't support focus-within, fake it:
  document.body.addEventListener("focusin", updateFocus);
  document.body.addEventListener("focusout", updateFocus);
  function updateFocus(e: FocusEvent) {
    for (let el = document.activeElement; el; el = el.parentElement) {
      if (el.id === "nav-menu") {
        document.body.classList.add("is-mobile-nav");
        return;
      }
    }
    document.body.classList.remove("is-mobile-nav");
  }
  // You can also open and close the menu with the mouse:
  const openButton = document.getElementById("open-menu-button");
  const closeButton = document.getElementById("close-menu-button");
  openButton?.addEventListener("click", (e) => {
    document.body.classList.add("is-mobile-nav");
    closeButton?.focus();
  });
  closeButton?.addEventListener("click", (e) => {
    document.body.classList.remove("is-mobile-nav");
    openButton?.focus();
  });

  const reporterMessaging = document.getElementById("reporterMessaging");
  if (reporterMessaging) {
    const showCopyWarning = reporterMessaging?.getAttribute("data-warning") === "true";
    render(
      <LanguageProvider value={language}>
        <ToastProvider>
          <ReporterMessaging showCopyWarning={showCopyWarning} sla={window.sla} />
        </ToastProvider>
      </LanguageProvider>,
      reporterMessaging,
    );
  }

  const form = document.getElementById("report-form-target");
  if (form) {
    hydrate(
      <LanguageProvider value={language}>
        <ReportForm
          formId={form.dataset.formId!}
          csrfToken={form.dataset.csrfToken!}
          pageHistory={form.dataset.pageHistory!.split(",").map((p) => parseInt(p, 10))}
          pages={JSON.parse(form.dataset.pages!)}
          answers={JSON.parse(form.dataset.answers!)}
          sessionId={form.dataset.sessionId!}
          uploadWarning={null}
          csrfFailure={!!form.dataset.csrfFailure}
          ssoProvider={window.env.cognitoSsoProvider ?? null}
          isSurvey={form.dataset.isSurvey !== undefined}
        />
      </LanguageProvider>,
      form,
    );
  } else {
    if (document.body.dataset.objectType && document.body.dataset.objectId) {
      sendBeacon(document.body.dataset.objectType, document.body.dataset.objectId, null, null, null);
    }
  }

  initTitleObscurer();
  wireUpFormLinks();
}

export default function main() {
  document.body.classList.add("js");

  Sentry.init({
    dsn: "https://9ff38e13b46b4cf9967754906b046d5b@o392151.ingest.sentry.io/5240403",
    release: window.env.sentryRelease,
    environment: window.env.sentryEnvironment,
    beforeBreadcrumb: (breadcrumb: Breadcrumb) => ({
      ...breadcrumb,
      message: breadcrumb.message?.replace(uuids, "REDACTED-UUID"),
    }),
  });

  if (document.readyState === "loading") {
    window.addEventListener("DOMContentLoaded", onContentLoad);
  } else {
    onContentLoad();
  }
}
