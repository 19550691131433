import React, { FunctionComponent } from "react";

import { AnswerTemplate } from "../../db/models/Answer";
import Question from "../../db/models/Question";
import { Translated, translateDbString } from "../report-utils/Translated";
import { useLanguage } from "../translation/frontend";
import QuestionImage from "./QuestionImage";
import QuestionOptions from "./QuestionOptions";
import TextInput from "./TextInput";
import { onlyIfMatches } from "./page-logic";
import useInvalidQuestions from "./useInvalidQuestions";

interface QuestionProps {
  question: Question;
  answers: Record<string, AnswerTemplate[]>;
}

function QuestionInput({ question, answers }: { question: Question; answers: AnswerTemplate[] }) {
  switch (question.questionType) {
    case "TEXT":
    case "EMAIL":
    case "PHONE":
    case "DATE":
    case "DATETIME":
    case "TIME":
    case "TEXTAREA":
      return <TextInput field={{ question }} defaultValue={answers[0]?.freeText} />;
    case "RADIO":
    case "CHECKLIST":
      return <QuestionOptions question={question} answers={answers} />;
    case "IMAGE": {
      return <QuestionImage question={question} answers={answers} />;
    }
    default:
      throw new Error("unknown question type");
  }
}

export function QuestionTitle({
  question,
  answers,
}: {
  question: Question;
  answers: Record<string, AnswerTemplate[]>;
}) {
  for (const contextAwareTitle of question.contextAwareTitles) {
    if (onlyIfMatches(contextAwareTitle.when, answers)) {
      return <Translated translations={contextAwareTitle.titleTranslations}>{contextAwareTitle.title}</Translated>;
    }
  }

  return <Translated translations={question.titleTranslations}>{question.title}</Translated>;
}

function QuestionRequiredPrompt({ question }: { question: Question }) {
  const language = useLanguage();
  const label = translateDbString(question.requiredPromptTranslations, language) ?? question.requiredPrompt;

  return question.requiredPrompt ? (
    <span className="subtext">
      {label?.trim()[0] !== "(" ? <span aria-hidden>{"– "}</span> : null}
      {label}
    </span>
  ) : null;
}

function QuestionAdditionalInfo({ question }: { question: Question }) {
  const language = useLanguage();

  return question.additionalInfo ? (
    <div
      className="question-text"
      dangerouslySetInnerHTML={{
        __html: translateDbString(question.additionalInfoTranslations, language) ?? question.additionalInfo,
      }}
    />
  ) : null;
}

const QuestionComponent: FunctionComponent<QuestionProps> = ({ question, answers }) => {
  const { questionIsInvalid } = useInvalidQuestions();
  return (
    <div className={`field ${questionIsInvalid(question) ? "field__invalid" : ""}`} id={`${question.id}-container`}>
      <label className="question" htmlFor={question.id}>
        <QuestionTitle question={question} answers={answers} />
        <QuestionRequiredPrompt question={question} />
      </label>
      <QuestionAdditionalInfo question={question} />
      <QuestionInput question={question} answers={answers[question.id] || []} />
    </div>
  );
};

export default QuestionComponent;
